import {
    SET_LOADING, SET_ANNOUNCEMENT, SET_ANNOUNCEMENTS, SET_ANNOUNCEMENTS_TOTAL,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchAnnouncements({ commit },  params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/announcements",params)
        .then((result) => {
          result =result.data;
          commit(SET_ANNOUNCEMENTS, result?.data ?? []);
          commit(SET_ANNOUNCEMENTS_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
          commit(SET_LOADING, false);
        });
  },
  async fetchAnnouncement({ commit }, id) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get(`backend/announcements/${id}`)
        .then((result) => {
          commit(SET_ANNOUNCEMENT, result?.data ?? {});
          commit(SET_LOADING, false);
          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
          commit(SET_ANNOUNCEMENT, { success: false });

        });

  },
  async createAnnouncement({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.post("backend/announcements", params)
        .then((result) => {
          result = result.data;
          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response.data);
          return response.data;
        });
  },
  async updateAnnouncement({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.put("backend/announcements/"+params.id, params)
        .then((result) => {
          result = result.data;

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response.data);
          return response.data;
        });
  },

  async deleteAnnouncement({ commit }, id) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.delete(`backend/announcements/${id}`)
        .then(async (result) => {
          // console.log('delete success',result.data)
          return result.data;
        })
        .catch((response) => {
          // console.log('delete error response', response.data);
        });
  },
};

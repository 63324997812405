import Swal from "sweetalert2";

import {

  SET_LOADING, SET_BUDGET
} from "./mutations";
import ApiService from "@/core/services/ApiService";
import {SET_SERVICE_FORM} from "@/modules/service_form/store/mutations";

export default {
  
  async fetchBudget({ commit },  params = {}) {
    commit(SET_LOADING, true);

    ApiService.setHeader();
    return await ApiService.query("backend/quotations_budget?quotation_id="+params)
        .then((result) => {
          result =result.data;
          commit(SET_BUDGET, result?.data ?? []);
          commit(SET_LOADING, false);
          // console.log('result', result)
          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
        });


  },
  async createBudget({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
      ApiService.setHeader();
      return await ApiService.post("backend/quotations_budget", params)
          .then((result) => {

              if(result.id){
                Swal.fire({
                  title: '費用試算表儲存成功!',
                }).then(result => {
                  location.href = '/#/trialBalance/list'
                })
              }
          })
          .catch(({response}) => {
              // console.log('error response', response);
          });
  },
  async updateBudget({ commit },params) {
    commit(SET_LOADING, true);
      ApiService.setHeader();
      return await ApiService.put("backend/quotations_budget/"+params.quotation_id, params)
          .then((result) => {
            Swal.fire({
              title: '費用試算表更新成功!',
            }).then(result => {
              location.href = '/#/trialBalance/list'
            })
          })
          .catch(({response}) => {
              // console.log('error response', response);
          });
  },

  async deleteProposal({ commit }, params) {
    commit(SET_LOADING, true);
      ApiService.setHeader();
      return await ApiService.delete("backend/proposals/"+params)
          .then(async (result) => {
              Swal.fire({
                title: '廠商資料刪除成功!',
              }).then(result => {
                history.go(-1)
              })
          })
          .catch((response) => {
              // console.log('delete error response', response);
          });
  },
};

import {
    SET_LOADING, SET_DEPARTMENTS, SET_DEPARTMENTS_TOTAL,
} from "./mutations";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2";

export default {
  async fetchDepartments({ commit },  params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/departments",params)
        .then((result) => {
          result =result.data;
          commit(SET_DEPARTMENTS, result?.data ?? []);
          commit(SET_DEPARTMENTS_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
        });
  },
  async updateFile({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
      ApiService.setHeader();
      return await ApiService.put(`backend/apply_forms/${params.id}/update_attach`, {'file_list':params.file_list})
          .then((result) => {
              result =result.data;
              // console.log('result', result)
              if(result){
                Swal.fire({
                  title: '憑證資料更新成功!',
                }).then(result => {
                  // location.href = '/#/departmentRequisition/list'
                })
              }
          })
          .catch(({response}) => {
              // console.log('error response', response);
          });
  },
};

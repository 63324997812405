import {
    SET_LOADING, SET_INVOICES, SET_INVOICES_TOTAL,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchInvoices({ commit },  params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/invoices",params)
        .then((result) => {
          result =result.data;
          commit(SET_INVOICES, result?.data ?? []);
          commit(SET_INVOICES_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
        });
  },
};

import {
    SET_LOADING, SET_APPROVWLISTS, SET_APPROVWLISTS_TOTAL,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchApproveLists({ commit },  params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/approve_lists",params)
        .then((result) => {
          result =result.data;
          commit(SET_APPROVWLISTS, result?.data ?? []);
          commit(SET_APPROVWLISTS_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
          commit(SET_LOADING, false);
        });
  },
  async createApproveLists({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.post("backend/approve_lists", params)
        .then((result) => {
          result = result.data;
          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response.data);
          return response.data;
        });
  },
};

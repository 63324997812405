import Swal from "sweetalert2";
import {
    SET_LOADING,
    SET_QUOTATION,
    SET_QUOTATION_TOTAL,
    SET_PM_LIST,
    SET_PROPOSAL_LIST,
    SET_PLAN_LIST,
    SET_QUOTATION_FORM_DATA,
    SET_QUOTATIONDATA, SET_PAYMENT_LIST
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
    async fetchQuotationList({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("/backend/quotations?status=2", params)
            .then((result) => {
                result = result.data;
                commit(SET_QUOTATION, result?.data ?? []);
                commit(SET_QUOTATION_TOTAL, result?.total ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async fetchQuotationData({commit}, id) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.get(`/backend/quotations`, id)
            .then((result) => {
                result = result.data;
                commit(SET_QUOTATIONDATA, result ? result : {});
                commit(SET_LOADING, false);
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
                commit(SET_QUOTATIONDATA, {success: false});

            });

    },
    async fetchPMList({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("/backend/users?is_pm=1", params)
            .then((result) => {
                result = result.data;
                commit(SET_PM_LIST, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async fetchProposalList({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("/backend/proposals", params)
            .then((result) => {
                result = result.data;
                commit(SET_PROPOSAL_LIST, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async fetchPlanList({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("/backend/plan_types", params)
            .then((result) => {
                result = result.data;
                commit(SET_PLAN_LIST, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async fetchApplyForms({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.post("/backend/apply_forms", params)
            .then((result) => {
                result = result.data;
                // commit(SET_APPLY_QUOTATION, result?.data ?? []);
                commit(SET_LOADING, false);
                // console.log('result', result)
                if (result.id) {
                    Swal.fire({
                        title: '報價單送出成功!',
                    }).then(result => {
                        history.go(-1)
                    })
                } else {
                    Swal.fire({
                        title: '請確保所有必填欄位已填寫完畢！',
                    })
                }

                return result;
            })
            .catch(({response}) => {
                Swal.fire({
                    title: '送出失敗!',
                    text: response,
                })
                // console.log('error response', response);
            });
    },
    async updateApplyForms({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/apply_forms/" + params.id, params)
            .then((result) => {
                result = result.data;

                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async fetchFormData({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query(`/backend/apply_forms/${params.value}`)
            .then((result) => {
                result = result.data;
                commit(SET_QUOTATION_FORM_DATA, result ? result : []);
                commit(SET_LOADING, false);

                // console.log('result.data',result)
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response.data);
                // commit(SET_QUOTATION_FORM_DATA,response.data);
                return {'success':false,message:''}
            });
    },
    async fetchPlanListItem({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("/backend/plan_types/" + params.id, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async createPlanList({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.post("backend/plan_types", params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async updatePlanList({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/plan_types/" + params.id, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async deletePlanList({commit}, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.delete("backend/plan_types/" + params)
            .then(async (result) => {
                return result.data;
            })
            .catch((response) => {
                // console.log('delete error response', response);
            });
    },
    async deleteQuotation({commit}, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.delete("backend/quotations/" + params)
            .then(async (result) => {
                return result.data;
            })
            .catch((response) => {
                // console.log('delete error response', response);
            });
    },
    async CPQuotation({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/quotations/" + params.id+"/copy", params)
            .then((result) => {
                result = result.data;
                if (result.id) {
                    Swal.fire({
                        title: '複製成功!',
                    }).then(result => {
                        // history.go(-1)
                    })
                } else {

                }
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async AddonQuotation({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/quotations/" + params.id+"/addon", params)
            .then((result) => {
                result = result.data;
                if (result.id) {
                    Swal.fire({
                        title: '追加成功!',
                    }).then(result => {
                        // history.go(-1)
                    })
                } else {

                }
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async drawThisForm({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/quotations/" + params.id+"/draw_form", params)
            .then((result) => {
                result = result.data;
                if (result.id) {
                    Swal.fire({
                        title: '抽單成功!',
                    }).then(result => {
                        // history.go(-1)
                    })
                } else {

                }
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async fetchPaymentList({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("/backend/payment_methods", params)
            .then((result) => {
                result = result.data;
                commit(SET_PAYMENT_LIST, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
};

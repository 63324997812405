import {
    SET_LOADING, SET_PERMISSIONS,SET_PERMISSIONS_TOTAL,SET_PERMISSION,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchPermissions({ commit },  params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/permissions",params)
        .then((result) => {
          commit(SET_PERMISSIONS, result?.data ?? []);
          commit(SET_PERMISSIONS_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
        });
  },
  async fetchPermission({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/permissions",params)
        .then((result) => {
          result =result.data;
          commit(SET_PERMISSION, result?.data ?? {});
          commit(SET_LOADING, false);
          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
        });

  },
};

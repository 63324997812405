import {ref} from "vue";
import {defineStore} from "pinia";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import AuthService from "@/core/services/AuthService";

export interface User {
    name: string;
    id: number;
    surname: string;
    email: string;
    password: string;
    token: string;
    roles: Array<{ permissions: any }>,
    permissions: Array<any>,
}

export const useAuthStore = defineStore("auth", () => {
    const errors = ref({});
    const user = ref<User>({} as User);
    const isAuthenticated = ref(!!JwtService.getToken());

    function setAuth(authUser: User) {
        isAuthenticated.value = true;
        user.value = authUser;
        errors.value = {};
        if (user.value?.token && user.value.token != "") {
            JwtService.saveToken(user.value.token);
        }

        let temp = [];
        user.value?.roles.forEach(role => {
            return role?.permissions.forEach(permission => {
                return temp.push(permission.name);
            })
        })

        user.value?.permissions.forEach(per => {
                return temp.push(per.name);

        })

        AuthService.savePermission(JSON.stringify(temp));
        AuthService.saveUser(JSON.stringify(user.value));
    }

    function setError(error: any) {
        errors.value = {...error};
    }

    function purgeAuth() {
        let id=0;
        if(user && user.value && typeof user.value.id !== "undefined" ){
            // console.log(user.value)
           id= user.value.id
        }

        isAuthenticated.value = false;
        user.value = {} as User;
        errors.value = [];
        JwtService.destroyToken();
        AuthService.destroyPermission();
        AuthService.destroyUser();

        // return ApiService.post("logout", {id:id})
        //     .then(({data}) => {
        //
        //     })
        //     .catch(({response}) => {
        //         // console.log(response.data)
        //         setError(response.data.errors);
        //     });
    }

    function login(credentials: User) {
        return ApiService.post("login", credentials)
            .then(({data}) => {
                setAuth(data);
                return data;
            })
            .catch(({response}) => {
                // console.log(response.data)
                setError(response.data.errors);
            });
    }

    function logout() {
        purgeAuth();
    }

    function forgotPassword(email: string) {
        return ApiService.post("backend/forget_password", email)
            .then(({data}) => {
                if (data.success) {
                    setError({});
                } else {
                    setError(data.error);
                }

            })
            .catch(({response}) => {
                setError(response.data.errors);
            });
    }

    function checkToken(token: string) {
        return ApiService.post("backend/forget_password/check", token)
            .then(({data}) => {
                if (data.success) {
                    setError({});
                } else {
                    setError(data.error);
                }

            })
            .catch(({response}) => {
                setError(response.data.errors);
            });
    }

    function updatePassword(token: string) {
        return ApiService.post("backend/forget_password/update", token)
            .then(({data}) => {
                if (data.success) {
                    setError({});
                } else {
                    setError(data.error);
                }

            })
            .catch(({response}) => {
                setError(response.data.message);
            });
    }

    function verifyAuth() {
        if (JwtService.getToken()) {
            // ApiService.setHeader();
            // ApiService.post("backend/verify_token", { api_token: JwtService.getToken() })
            //   .then(({ data }) => {
            //
            //   })
            //   .catch(({ response }) => {
            //     console.log('response',response);
            //     setError(response.data.errors);
            //     purgeAuth();
            //   });
        } else {
            purgeAuth();
        }
    }

    function getUser() {
        return user.value;
    }

    return {
        errors,
        user,
        isAuthenticated,
        login,
        setAuth,
        logout,
        getUser,
        forgotPassword,
        verifyAuth,
        checkToken,
        updatePassword,
    };
});

import {
  createRouter,
  createWebHashHistory,
  type RouteRecordRaw,
} from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useConfigStore } from "@/stores/config";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/dashboard/setting",
        name: "dashboard-setting",
        component: () => import("@/views/dashboard/Setting.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/users/edit/me",
        name: "user-edit-me-detail",
        component: () => import("@/views/me/Detail.vue"),
        meta: {
          pageTitle: "使用者資料",
          breadcrumbs: ["使用者資料"],
        },
      },
      {
        path: "/user/list/:user_id",
        name: "user-detail",
        component: () => import("@/views/users/Detail.vue"),
        meta: {
          pageTitle: "使用者資料",
          breadcrumbs: ["使用者資料"],
        },
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/users/List.vue"),
        meta: {
          pageTitle: "使用者清單",
          breadcrumbs: ["使用者資料"],
        },
        children: [
          {
            path: "list",
            name: "user-list",
            component: () => import("@/views/users/List.vue"),
            meta: {
              pageTitle: "使用者清單",
              breadcrumbs: ["使用者清單",],
            },
          },

        ],
      },
      {
        path: "/role/list/:role_id",
        name: "role-detail",
        component: () => import("@/views/roles/Detail.vue"),
        meta: {
          pageTitle: "角色管理",
          breadcrumbs: ["角色管理"],
        },
      },
      {
        path: "/role",
        name: "role",
        component: () => import("@/views/roles/List.vue"),
        meta: {
          pageTitle: "角色管理",
          breadcrumbs: ["角色管理"],
        },
        children: [
          {
            path: "list",
            name: "role-list",
            component: () => import("@/views/roles/List.vue"),
            meta: {
              pageTitle: "角色管理",
              breadcrumbs: ["角色管理",],
            },
          },

        ],
      },
      {
        path: "/proposals/list/:proposal_id",
        name: "proposal-detail",
        component: () => import("@/views/proposals/Detail_v2.vue"),
        meta: {
          pageTitle: "廠商",
          breadcrumbs: ["廠商"],
        },
      },
      {
        path: "/proposals",
        name: "proposal",
        component: () => import("@/views/proposals/List.vue"),
        meta: {
          pageTitle: "Proposal List",
          breadcrumbs: ["Proposal", "List"],
        },
        children: [
          {
            path: "list",
            name: "proposal-list",
            component: () => import("@/views/proposals/List.vue"),
            meta: {
              pageTitle: "廠商",
              breadcrumbs: ["廠商"],
            },
          },

        ],
      },
      {
        path: "/signature/Detail/:signature_id",
        name: "signature-detail",
        component: () => import("@/views/signature/Detail.vue"),
        meta: {
          pageTitle: "簽核發起",
          breadcrumbs: ["簽核發起"],
        },
      },
      {
        path: "/signature/start",
        name: "signature-start",
        component: () => import("@/views/signature/start.vue"),
        meta: {
          pageTitle: "選擇要發起的簽核",
          breadcrumbs: ["選擇要發起的簽核"],
        },
      },
      {
        path: "/signature/List",
        name: "signature",
        component: () => import("@/views/signature/List.vue"),
        meta: {
          pageTitle: "表單追蹤",
          breadcrumbs: ["表單追蹤"],
        },
        children: [
          {
            path: "signature",
            name: "signature-list",
            component: () => import("@/views/signature/List.vue"),
            meta: {
              pageTitle: "表單追蹤",
              breadcrumbs: ["表單追蹤",],
            },
          },

        ],
      },
      {
        path: "/post/Detail",
        name: "post-detail",
        component: () => import("@/views/post/Detail.vue"),
        meta: {
          pageTitle: "公告管理",
          breadcrumbs: ["公告管理"],
        },
      },
      {
        path: "/post/Page/",
        name: "post-detail-page",
        component: () => import("@/views/post/Page.vue"),
        meta: {
          pageTitle: "公告",
          breadcrumbs: ["公告"],
        },
      },
      {
        path: "/post/List",
        name: "post",
        component: () => import("@/views/post/List.vue"),
        meta: {
          pageTitle: "公告管理列表",
          breadcrumbs: ["公告管理列表"],
        },
        children: [
          {
            path: "post",
            name: "post-list",
            component: () => import("@/views/post/List.vue"),
            meta: {
              pageTitle: "公告管理列表",
              breadcrumbs: ["公告管理列表",],
            },
          },

        ],
      },
      {
        path: "/message/list",
        name: "message-list",
        component: () => import("@/views/message/List.vue"),
        meta: {
          pageTitle: "訊息管理",
          breadcrumbs: ["訊息管理"],
        },
      },
      // {
      //   path: "/forms/List",
      //   name: "forms",
      //   component: () => import("@/views/forms/List.vue"),
      //   meta: {
      //     pageTitle: "表單列表",
      //     breadcrumbs: ["表單列表"],
      //   },
      //   children: [
      //     {
      //       path: "forms",
      //       name: "forms-list",
      //       component: () => import("@/views/forms/List.vue"),
      //       meta: {
      //         pageTitle: "表單列表",
      //         breadcrumbs: ["表單列表",],
      //       },
      //     },
      //   ],
      // },
      // {
      //   path: "/forms/Quotation/:forms_id",
      //   name: "forms-quotation",
      //   component: () => import("@/views/forms/Quotation.vue"),
      //   meta: {
      //     pageTitle: "請款單",
      //     breadcrumbs: ["請款單"],
      //   },
      // },
      {
        path: "/userFormList/List",
        name: "userFormList-List",
        component: () => import("@/views/userFormList/List.vue"),
        meta: {
          pageTitle: "表單簽核",
          breadcrumbs: ["表單簽核"],
        },
      },
      {
        path: "/contractNo/List",
        name: "contractNo-List",
        component: () => import("@/views/contractNo/List.vue"),
        meta: {
          pageTitle: "合約編號產生器",
          breadcrumbs: ["合約編號產生器"],
        },
      },
      {
        path: "/quotation/List",
        name: "quotation-List",
        component: () => import("@/views/quotation/List.vue"),
        meta: {
          pageTitle: "報價單列表",
          breadcrumbs: ["報價單列表"],
        },
      },
      {
        path: "/departmentRequisition/List",
        name: "departmentRequisition-List",
        component: () => import("@/views/departmentRequisition/List.vue"),
        meta: {
          pageTitle: "請款單列表",
          breadcrumbs: ["請款單列表"],
        },
      },

      {
        path: "/departmentRequisition/help",
        name: "departmentRequisition-help",
        component: () => import("@/views/departmentRequisition/Help.vue"),
        meta: {
          pageTitle: "請款單列表",
          breadcrumbs: ["請款單列表"],
        },
      },

      {
        path: "/departmentRequisition/desc",
        name: "departmentRequisition-desc",
        component: () => import("@/views/departmentRequisition/Desc.vue"),
        meta: {
          pageTitle: "請款單列表",
          breadcrumbs: ["請款單列表"],
        },
      },
      {
        path: "/creditCardRequisition/List",
        name: "creditCardRequisition-List",
        component: () => import("@/views/creditCardRequisition/List.vue"),
        meta: {
          pageTitle: "信用卡請款單列表",
          breadcrumbs: ["信用卡請款單列表"],
        },
      },
      {
        path: "/invoiceApplication/List",
        name: "invoiceApplication-List",
        component: () => import("@/views/invoiceApplication/List.vue"),
        meta: {
          pageTitle: "發票申請單列表",
          breadcrumbs: ["發票申請單列表"],
        },
      },
      {
        path: "/travel/List",
        name: "travel-list",
        component: () => import("@/views/travel/List.vue"),
        meta: {
          pageTitle: "差旅費申請單列表",
          breadcrumbs: ["差旅費申請單列表"],
        },
      },
      {
        path: "/study/List",
        name: "study-list",
        component: () => import("@/views/study/List.vue"),
        meta: {
          pageTitle: "個人學習補助申請單列表",
          breadcrumbs: ["個人學習補助申請單列表"],
        },
      },
      {
        path: "/stampApplication/List",
        name: "stampApplication-List",
        component: () => import("@/views/stampApplication/List.vue"),
        meta: {
          pageTitle: "用印申請單列表",
          breadcrumbs: ["用印申請單列表"],
        },
      },
      {
        path: "/accountApplication/List",
        name: "accountApplication-List",
        component: () => import("@/views/accountApplication/List.vue"),
        meta: {
          pageTitle: "帳號申請單列表",
          breadcrumbs: ["帳號申請單列表"],
        },
      },
      {
        path: "/quotation/new",
        name: "quotation-new",
        component: () => import("@/views/quotation/new.vue"),
        meta: {
          pageTitle: "報價單發起",
          breadcrumbs: ["報價單發起"],
        },
      },
      {
        path: "/departmentRequisition/new",
        name: "departmentRequisition-new",
        component: () => import("@/views/departmentRequisition/new.vue"),
        meta: {
          pageTitle: "請款單發起",
          breadcrumbs: ["請款單發起"],
        },
      },
      {
        path: "/creditCardRequisition/new",
        name: "creditCardRequisition-new",
        component: () => import("@/views/creditCardRequisition/new.vue"),
        meta: {
          pageTitle: "信用卡請款單發起",
          breadcrumbs: ["信用卡請款單發起"],
        },
      },
      {
        path: "/invoiceApplication/new",
        name: "invoiceApplication-new",
        component: () => import("@/views/invoiceApplication/new.vue"),
        meta: {
          pageTitle: "發票申請單發起",
          breadcrumbs: ["發票申請單發起"],
        },
      },
      {
        path: "/travel/aday",
        name: "travel-aday",
        component: () => import("@/views/travel/aday.vue"),
        meta: {
          pageTitle: "拜訪客戶差旅費申請單發起",
          breadcrumbs: ["拜訪客戶差旅費申請單發起"],
        },
      },
      {
        path: "/travel/domestic",
        name: "travel-domestic",
        component: () => import("@/views/travel/domestic.vue"),
        meta: {
          pageTitle: "國內差旅費申請單發起",
          breadcrumbs: ["國內差旅費申請單發起"],
        },
      },
      {
        path: "/travel/foreign",
        name: "travel-foreign",
        component: () => import("@/views/travel/foreign.vue"),
        meta: {
          pageTitle: "國外差旅費申請單發起",
          breadcrumbs: ["國外差旅費申請單發起"],
        },
      },
      {
        path: "/study/new",
        name: "study-new",
        component: () => import("@/views/study/New.vue"),
        meta: {
          pageTitle: "個人學習補助申請單發起",
          breadcrumbs: ["個人學習補助申請單發起"],
        },
      },
      {
        path: "/stampApplication/new",
        name: "stampApplication-new",
        component: () => import("@/views/stampApplication/new.vue"),
        meta: {
          pageTitle: "用印申請單發起",
          breadcrumbs: ["用印申請單發起"],
        },
      },
      {
        path: "/accountApplication/new",
        name: "accountApplication-new",
        component: () => import("@/views/accountApplication/new.vue"),
        meta: {
          pageTitle: "帳號申請單發起",
          breadcrumbs: ["帳號申請單發起"],
        },
      },
      {
        path: "/organize/index",
        name: "organize-page",
        component: () => import("@/views/organize/index.vue"),
        meta: {
          pageTitle: "MYFEEL 組織圖",
          breadcrumbs: ["MYFEEL 組織圖"],
        }
      },
      {
        path: "/organize/seat",
        name: "organize-seat",
        component: () => import("@/views/organize/seat.vue"),
        meta: {
          pageTitle: "MYFEEL 座位表",
          breadcrumbs: ["MYFEEL 座位表"],
        }
      },
      {
        path: "/education",
        name: "education",
        component: () => import("@/views/education/List.vue"),
        meta: {
          pageTitle: "新手上路",
          breadcrumbs: ["新手上路"],
        },
      },
      {
        path: "/education/goAhead",
        name: "education-go-ahead",
        component: () => import("@/views/education/goAhead/List.vue"),
        meta: {
          pageTitle: "在職進修",
          breadcrumbs: ["在職進修"],
        },
      },
      {
        path: "/education/cooperativeStores",
        name: "education-cooperativeStores",
        component: () => import("@/views/education/cooperativeStores/List.vue"),
        meta: {
          pageTitle: "感覺來了",
          breadcrumbs: ["感覺來了"],
        },
      },
      {
        path: "/education/Page/",
        name: "education-page",
        component: () => import("@/views/education/Page.vue"),
        meta: {
          pageTitle: "制度及資源中心",
          breadcrumbs: ["制度及資源中心"],
        },
      },
      {
        path: "/education/new",
        name: "education-new",
        component: () => import("@/views/education/new.vue"),
        meta: {
          pageTitle: "制度及資源中心",
          breadcrumbs: ["制度及資源中心"],
        },
      },
      {
        path: "/mgmt/list/:apply_id",
        name: "mgmt-detail",
        component: () => import("@/views/proposals/Detail_v2.vue"),
        meta: {
          pageTitle: "管理功能",
          breadcrumbs: ["管理功能"],
        },
      },
      {
        path: "/mgmts/quotation/list",
        name: "mgmt-quotation-list",
        component: () => import("@/views/mgmt/quotation/List.vue"),
        meta: {
          pageTitle: "報價單-管理",
          breadcrumbs: ["報價單"],
        },
      },
      {
        path: "/mgmts/departmentRequisition/list",
        name: "mgmt-departmentRequisition-list",
        component: () => import("@/views/mgmt/departmentRequisition/List.vue"),
        meta: {
          pageTitle: "部門請款單列表-管理",
          breadcrumbs: ["部門請款單列表"],
        },
      },
      {
        path: "/mgmts/creditCardRequisition/list",
        name: "mgmt-creditCardRequisition-list",
        component: () => import("@/views/mgmt/creditCardRequisition/List.vue"),
        meta: {
          pageTitle: "信用卡請款單列表-管理",
          breadcrumbs: ["信用卡請款單列表"],
        },
      },
      {
        path: "/mgmts/invoiceApplication/list",
        name: "mgmt-invoiceApplication-list",
        component: () => import("@/views/mgmt/invoiceApplication/List.vue"),
        meta: {
          pageTitle: "發票申請單列表-管理",
          breadcrumbs: ["發票申請單列表"],
        },
      },
      {
        path: "/mgmts/stampApplication/list",
        name: "mgmt-stampApplication-list",
        component: () => import("@/views/mgmt/stampApplication/List.vue"),
        meta: {
          pageTitle: "用印申請單列表-管理",
          breadcrumbs: ["用印申請單列表"],
        },
      },
      {
        path: "/mgmts/accountApplication/list",
        name: "mgmt-accountApplication-list",
        component: () => import("@/views/mgmt/accountApplication/List.vue"),
        meta: {
          pageTitle: "帳號申請單列表-管理",
          breadcrumbs: ["帳號申請單列表"],
        },
      },
      {
        path: "/mgmts/configList/list",
        name: "mgmt-configList-list",
        component: () => import("@/views/mgmt/ConfigList/List.vue"),
        meta: {
          pageTitle: "報價單請款類別",
          breadcrumbs: ["報價單請款類別"],
        },
      },
      {
        path: "/mgmts/stampType/list",
        name: "mgmt-stampType-list",
        component: () => import("@/views/mgmt/stampType/List.vue"),
        meta: {
          pageTitle: "用印類別",
          breadcrumbs: ["用印類別"],
        },
      },
      {
        path: "/mgmts/contractNo/list",
        name: "mgmt-contractNo-list",
        component: () => import("@/views/mgmt/contractNo/List.vue"),
        meta: {
          pageTitle: "合約編號產生器",
          breadcrumbs: ["合約編號產生器"],
        },
      },
      {
        path: "/mgmts/stampType/detail",
        name: "mgmt-stampType-detail",
        component: () => import("@/views/mgmt/stampType/new.vue"),
        meta: {
          pageTitle: "用印類別",
          breadcrumbs: ["用印類別"],
        },
      },
      {
        path: "/mgmts/configList/detail",
        name: "mgmt-configList-detail",
        component: () => import("@/views/mgmt/ConfigList/new.vue"),
        meta: {
          pageTitle: "報價單請款類別",
          breadcrumbs: ["報價單請款類別"],
        },
      },
      {
        path: "/trialBalance/List",
        name: "trialBalance-List",
        component: () => import("@/views/trialBalance/List.vue"),
        meta: {
          pageTitle: "試算表列表",
          breadcrumbs: ["試算表列表"],
        },
      },
      {
        path: "/quotation_config/List",
        name: "quotation_config-List",
        component: () => import("@/views/quotation_config/List.vue"),
        meta: {
          pageTitle: "報價單類別設定",
          breadcrumbs: ["報價單類別設定"],
        },
      },
      {
        path: "/quotation_config/ListSub",
        name: "quotation_config-ListSub",
        component: () => import("@/views/quotation_config/ListSub.vue"),
        meta: {
          pageTitle: "報價單類別設定",
          breadcrumbs: ["報價單類別設定"],
        },
      },
      {
        path: "/quotation_config/new",
        name: "quotation_config-new",
        component: () => import("@/views/quotation_config/new.vue"),
        meta: {
          pageTitle: "報價單類別設定",
          breadcrumbs: ["報價單類別設定"],
        },
      },
      {
        path: "/trialBalance/new",
        name: "trialBalance-new",
        component: () => import("@/views/trialBalance/new.vue"),
        meta: {
          pageTitle: "新增費用試算表",
          breadcrumbs: ["新增費用試算表"],
        },
      },
      {
        path: "/review/list/",
        name: "review-list",
        component: () => import("@/views/review/Index.vue"),
        meta: {
          pageTitle: "員工考核系統",
          breadcrumbs: ["員工考核系統首頁"],
        },
      },
      {
        path: "/review/scoreList/",
        name: "review-scoreList",
        component: () => import("@/views/review/ScoreList.vue"),
        meta: {
          pageTitle: "被評分人列表",
          breadcrumbs: ["被評分人列表"],
        },
      },
      {
        path: "/review/deptScoreList/",
        name: "review-deptScoreList",
        component: () => import("@/views/review/DeptScoreList.vue"),
        meta: {
          pageTitle: "公司 / 組別 評分列表",
          breadcrumbs: ["公司 / 組別 評分列表"],
        },
      },
      {
        path: "/review/deptScoreList/scoreGoal",
        name: "review-deptScoreList-scoreGoal",
        component: () => import("@/views/review/ScoreDeptGoal.vue"),
        meta: {
          pageTitle: "公司 / 組別 評分",
          breadcrumbs: ["公司 / 組別 評分"],
        },
      },
      {
        path: "/review/deptScoreList/deptGoalView",
        name: "review-deptScoreList-goalView",
        component: () => import("@/views/review/DeptGoalView.vue"),
        meta: {
          pageTitle: "公司 / 組別 評分",
          breadcrumbs: ["公司 / 組別 評分"],
        },
      },
      {
        path: "/review/scoreList/page",
        name: "review-scorePage",
        component: () => import("@/views/review/ScorePage.vue"),
        meta: {
          pageTitle: "評分表",
          breadcrumbs: ["評分表"],
        },
      },
      {
        path: "/review/scoreList/relatedPage",
        name: "review-scorePage-related",
        component: () => import("@/views/review/ScorePageRelated.vue"),
        meta: {
          pageTitle: "評分表",
          breadcrumbs: ["評分表"],
        },
      },
      {
        path: "/review/scoreList/page-view",
        name: "review-scorePage-view",
        component: () => import("@/views/review/ScorePageView.vue"),
        meta: {
          pageTitle: "檢視評分表",
          breadcrumbs: ["檢視評分表"],
        },
      },
      {
        path: "/review/scoreList/uploadPersonalGoal",
        name: "review-uploadGoal",
        component: () => import("@/views/review/UploadPersonalGoal.vue"),
        meta: {
          pageTitle: "上傳個人經營目標",
          breadcrumbs: ["上傳個人經營目標"],
        },
      },
      {
        path: "/review/scoreList/setGoal",
        name: "review-setPersonalGoal",
        component: () => import("@/views/review/SetGoalPage.vue"),
        meta: {
          pageTitle: "設定目標",
          breadcrumbs: ["設定目標"],
        },
      },
      {
        path: "/review/scoreList/setGoalByAdmin",
        name: "review-setPersonalGoalByAdmin",
        component: () => import("@/views/review/SetGoalPageByAdmin.vue"),
        meta: {
          pageTitle: "設定目標",
          breadcrumbs: ["設定目標"],
        },
      },
      {
        path: "/review/scoreList/setDeptGoal",
        name: "review-setDeptGoal",
        component: () => import("@/views/review/SetDeptGoalPage.vue"),
        meta: {
          pageTitle: "設定部門目標",
          breadcrumbs: ["設定部門目標"],
        },
      },
      {
        path: "/review/subordinateList",
        name: "review-subordinateList",
        component: () => import("@/views/review/SubordinateList.vue"),
        meta: {
          pageTitle: "下屬績效列表",
          breadcrumbs: ["下屬績效列表"],
        },
      },
      {
        path: "/review/employeeList",
        name: "review-employeeList",
        component: () => import("@/views/review/EmployeeList.vue"),
        meta: {
          pageTitle: "員工績效列表",
          breadcrumbs: ["員工績效列表"],
        },
      },
      {
        path: "/review/employeeDashboard",
        name: "review-employeeDashboard",
        component: () => import("@/views/review/EmployeeDashboard.vue"),
        meta: {
          pageTitle: "績效總覽",
          breadcrumbs: ["績效總覽"],
        },
      },
      {
        path: "/review/reconfirmPage",
        name: "review-reconfirmPage",
        component: () => import("@/views/review/ReconfirmPage.vue"),
        meta: {
          pageTitle: "試用期考核",
          breadcrumbs: ["試用期考核"],
        },
      },
      {
        path: "/review/reconfirmPageView",
        name: "review-reconfirmPage-view",
        component: () => import("@/views/review/ReconfirmPageView.vue"),
        meta: {
          pageTitle: "檢視試用期考核",
          breadcrumbs: ["檢視試用期考核"],
        },
      },
      {
        path: "/review/reconfirmRecord",
        name: "review-reconfirmRecord",
        component: () => import("@/views/review/ReconfirmRecord.vue"),
        meta: {
          pageTitle: "適用期評分紀錄",
          breadcrumbs: ["適用期評分紀錄"],
        },
      },
      {
        path: "/review/reconfirmList",
        name: "review-reconfirmLsit",
        component: () => import("@/views/review/ReconfirmList.vue"),
        meta: {
          pageTitle: "轉正考核列表",
          breadcrumbs: ["轉正考核列表"],
        },
      },
      {
        path: "/review/setting",
        name: "review-setting",
        component: () => import("@/views/review/Setting.vue"),
        meta: {
          pageTitle: "評分設定",
          breadcrumbs: ["評分設定"],
        },
      },
      {
        path: "/review/setting/team",
        name: "review-setting-team",
        component: () => import("@/views/review/SettingTeam.vue"),
        meta: {
          pageTitle: "組別評分項目設定",
          breadcrumbs: ["組別評分項目設定"],
        },
      },
      {
        path: "/review/setting/salary",
        name: "review-setting-salary",
        component: () => import("@/views/review/SettingSalary.vue"),
        meta: {
          pageTitle: "職務類別薪資級距設定",
          breadcrumbs: ["職務類別薪資級距設定"],
        },
      },
      {
        path: "/review/setting/employee",
        name: "review-setting-employee",
        component: () => import("@/views/review/SettingEmployee.vue"),
        meta: {
          pageTitle: "員工評分設定",
          breadcrumbs: ["員工評分設定"],
        },
      },
      {
        path: "/review/scoreList/toEveryone",
        name: "review-to-everyone",
        component: () => import("@/views/review/ToEveryone.vue"),
        meta: {
          pageTitle: "給大家的話",
          breadcrumbs: ["給大家的話"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
      {
        path: "/password-reset/:token",
        name: "password-reset-flow",
        component: () =>
            import("@/views/authentication/basic-flow/PasswordResetSetPassword.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const configStore = useConfigStore();

  // current page view title
  document.title = `${to.meta.pageTitle} - ${import.meta.env.VITE_APP_NAME}`;

  // reset config to initial state
  configStore.resetLayoutConfig();

  // verify auth token before each page change
  authStore.verifyAuth();

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (authStore.isAuthenticated) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;

import {
    SET_LOADING, SET_ACCOUNTS_TOTAL,SET_ACCOUNT,SET_ACCOUNTS
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchMgmtAccountForms({ commit },  params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("mgmt/account_request",params)
        .then((result) => {
          result =result.data;
          commit(SET_ACCOUNTS, result?.data ?? []);
          commit(SET_ACCOUNTS_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
          commit(SET_LOADING, false);
        });
  },
  async fetchMgmtAccountForm({ commit }, id) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get(`mgmt/account_request/${id}`)
        .then((result) => {
          commit(SET_ACCOUNT, result?.data ?? {});
          commit(SET_LOADING, false);
          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
          commit(SET_ACCOUNT, { success: false });

        });
  },
  async updateMgmtAccountForm({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.put("mgmt/account_request/"+params.apply_form_id, params)
        .then((result) => {
          result = result.data;

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response.data);
          return response.data;
        });
  },
};

import Swal from "sweetalert2";
import {
    SET_LOADING, SET_ORGANIZE, SET_DEPT, SET_SIGNER, UPDATE_DEPT
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
    async fetchOrganizeList({ commit },  params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("/backend/departments",params)
            .then((result) => {
              result =result.data;
              commit(SET_ORGANIZE, result?.data ?? []);
              commit(SET_LOADING, false);

              return result;
            })
            .catch(({response}) => {
              // console.log('error response', response);
            });
    },
    async fetchDepartment({ commit },  params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("/backend/departments/"+params.id)
            .then((result) => {
              result =result.data;
              commit(SET_DEPT, result?result:{});
              commit(SET_LOADING, false);

              return result;
            })
            .catch(({response}) => {
              // console.log('error response', response);
            });
    },
    async fetchSigner({ commit },  params = {}) {
      commit(SET_LOADING, true);
      ApiService.setHeader();
      return await ApiService.put("/backend/departments/"+params.deptId,params)
          .then((result) => {
            result =result.data;
            commit(SET_SIGNER, result?result:{});
            commit(SET_LOADING, false);

            return result;
          })
          .catch(({response}) => {
            // console.log('error response', response);
          });
    },
    async fetchUpdateDept({ commit },  params = {}) {
      commit(SET_LOADING, true);
      ApiService.setHeader();
      return await ApiService.put("/backend/departments/update",params)
          .then((result) => {
            result =result.data;
            commit(UPDATE_DEPT, result?result:{});
            commit(SET_LOADING, false);

            return result;
          })
          .catch(({response}) => {
            // console.log('error response', response);
          });
    },
};

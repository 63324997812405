import {
  SET_INDEX_CACHE,SET_LOADING
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchIndexCache({ commit }, params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/index", params)
      .then((result) => {
        result = result.data;
        commit(SET_INDEX_CACHE, result?.data ?? []);
        commit(SET_LOADING, false);

        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
};

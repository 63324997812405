import {
    SET_LOADING, SET_FORM, SET_FORMS, SET_FORMS_TOTAL,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
    async fetchForms({ commit },  params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("backend/apply_forms",params)
            .then((result) => {
                result =result.data;
                commit(SET_FORMS, result?.data ?? []);
                commit(SET_FORMS_TOTAL, result?.total ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async fetchForm({ commit }, id) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.get(`backend/apply_forms/${id}`)
            .then((result) => {
                commit(SET_FORM, result?.data ?? {});
                commit(SET_LOADING, false);
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response.data);
                commit(SET_FORM, { success: false,message: response.data.message });

            });

    },
    async createForm({ commit }, params) {
        commit(SET_LOADING, true, { root: true });
        ApiService.setHeader();
        return await ApiService.post("backend/apply_forms", params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response.data);
                return response.data;
            });
    },
    async updateForm({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/apply_forms/"+params.id, params)
            .then((result) => {
                result = result.data;

                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response.data);
                return response.data;
            });
    },

    async deleteForm({ commit }, id) {
        // console.log('deleteFormId',id);
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.delete(`backend/apply_forms/${id}`)
            .then(async (result) => {
                // console.log('delete success',result.data)
                return result.data;
            })
            .catch((response) => {
                // console.log('delete error response', response.data);
            });
    },

    async updateFormTax({commit}, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/apply_forms/"+params.apply_form_id+"/update_in_tax", params)
            .then((result) => {
                result = result.data;

                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response.data);
                return response.data;
            });
    },
    async drawForm({commit}, params={}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/apply_forms/"+params.apply_form_id+"/draw_form", params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response.data);
                return response.data;
            });
    }
};


import { SET_PROPOSAL_ORDER, SET_LOADING, SET_PROPOSAL_DASHBOARD ,SET_ORDER_LIST} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchProposalDashboard({ commit }, params = {}) {
    commit(SET_LOADING, true);

    ApiService.setHeader();
    return await ApiService.query("backend/proposal_dashboard", params)
      .then((result) => {
        result = result.data;
        // console.log(result)
        commit(SET_PROPOSAL_DASHBOARD, result ?? []);
        commit(SET_LOADING, false);
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
  async fetchProposalDashboardOrder({ commit }, params = []) {
    commit(SET_LOADING, true);
    ApiService.setHeader();

   let p= {
     proposal_id:params.join(',')
   }
    // let p=''
    // console.log('params', params.join(','));
    return await ApiService.query("backend/proposal_dashboard/order", p)
      .then((result) => {
        result = result.data;
        commit(SET_PROPOSAL_ORDER, result.data ?? []);
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
  async fetchProposalDashboardOrderList({ commit }, params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    let p= {
      proposal_id:params.join(',')
    }
    return await ApiService.query("backend/proposal_dashboard/order/list", p)
      .then((result) => {
        result = result.data;
        commit(SET_ORDER_LIST, result.data ?? []);
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
};

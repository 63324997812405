import {
  SET_LOADING,
  SET_LEARNING,
  SET_LEARNINGS,
  SET_LEARNINGS_TOTAL,
  SET_LEARNING_CATEGORIES,
  SET_LEARNING_CATEGORY,
  SET_LEARNINGS_CATEGORIES_TOTAL,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchLearningCategories({ commit }, params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/learning_categories", params)
      .then((result) => {
        result = result.data;
        commit(SET_LEARNING_CATEGORIES, result?.data ?? []);
        commit(SET_LEARNINGS_CATEGORIES_TOTAL, result?.total ?? []);
        commit(SET_LOADING, false);

        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
  async fetchLearningCategory({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/learning_categories/"+params.id)
      .then((result) => {
        result = result.data;
        commit(SET_LEARNING_CATEGORY, result);
        commit(SET_LOADING, false);

        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
  async createLearningCategory({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.post("backend/learning_categories", params)
      .then((result) => {
        return result.data;
      })
      .catch(({ response }) => {
        // console.log("error response", response.data);
        return response.data;
      });
  },
  async updateLearningCategory({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.put("backend/learning_categories/" + params.id, params)
      .then((result) => {
        
        result = result.data;
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response.data);
        return response.data;
      });
  },
  async deleteLearningCategory({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.delete("backend/learning_categories/"+ params.id)
      .then(async (result) => {
        result = result.data;
        return result;
      })
      .catch((response) => {
        // console.log("error response", response.data);
        return response.data;
      });
  },
  async fetchLearnings({ commit }, params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/learnings", params)
      .then((result) => {
        result = result.data;
        commit(SET_LEARNINGS, result?.data ?? []);
        commit(SET_LEARNINGS_TOTAL, result?.total ?? []);
        commit(SET_LOADING, false);

        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
  async fetchLearning({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get(`backend/learnings/`+ params.id)
      .then((result) => {
        result = result.data;
        commit(SET_LEARNING, result ?? {});
        commit(SET_LOADING, false);
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
        commit(SET_LEARNING, { success: false });
      });
  },
  async createLearning({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.post("backend/learnings", params)
      .then((result) => {
        return result.data;
      })
      .catch(({ response }) => {
        // console.log("error response", response.data);
        return response.data;
      });
  },
  async updateLearning({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.put("backend/learnings/" + params.id, params)
      .then((result) => {
        result = result.data;

        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response.data);
        return response.data;
      });
  },
  async deleteLearning({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.delete("backend/learnings/"+ params.id)
      .then(async (result) => {})
      .catch((response) => {
        // console.log("delete error response", response);
      });
  },
  async learningUser({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.post("backend/learning_user", params)
      .then((result) => {
        return result.data;
      })
      .catch(({ response }) => {
        // console.log("error response", response.data);
        return response.data;
      });
  },
};

import Swal from "sweetalert2";

import {

  SET_LOADING, SET_PROPOSAL, SET_PROPOSALS, SET_PROPOSALS_TOTAL,SET_PROPOSALS_CATE,SET_PROPOSALS_TYPE
} from "./mutations";
import ApiService from "@/core/services/ApiService";
import {SET_SERVICE_FORM} from "@/modules/service_form/store/mutations";

export default {

  async fetchProposals({ commit },  params = {}) {
    commit(SET_LOADING, true);

    ApiService.setHeader();
    return await ApiService.query("backend/proposals",params)
        .then((result) => {
          result =result.data;
          commit(SET_PROPOSALS, result?.data ?? []);
          commit(SET_PROPOSALS_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
        });


  },
  async fetchProposal({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/proposals", params)
        .then((result) => {
          result = result.data;
          commit(SET_PROPOSAL, result ?? {});
          commit(SET_LOADING, false);
          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response.data);
          commit(SET_PROPOSAL, { success: false });

        });
  },
    async setProposal({ commit }/**/) {
        commit(SET_PROPOSAL, {});
    },
  async createProposal({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
      ApiService.setHeader();
      return await ApiService.post("backend/proposals", params)
          .then((result) => {

              if(result.id){
                Swal.fire({
                  title: '廠商列表新增成功!',
                }).then(result => {
                  location.href = '/#/proposals/list'
                })
              }
          })
          .catch(({response}) => {
              // console.log('error response', response);
          });
  },
    async createProposalCode({ commit }, params) {
        commit(SET_LOADING, true, { root: true });
        ApiService.setHeader();
        return await ApiService.post("backend/proposals/"+params.proposal_id+"/createCode", params)
            .then((result) => {
                return result.data;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
  async updateProposal({ commit },params) {
    const id = params.proposal_id
    delete params.proposal_id
    commit(SET_LOADING, true);
      ApiService.setHeader();
      return await ApiService.put("backend/proposals/"+id, params)
          .then((result) => {
            Swal.fire({
              title: '廠商列表更新成功!',
            }).then(result => {
              location.href = '/#/proposals/list'
            })
          })
          .catch(({response}) => {
              // console.log('error response', response);
          });
  },

  async deleteProposal({ commit }, params) {
    commit(SET_LOADING, true);
      ApiService.setHeader();
      return await ApiService.delete("backend/proposals/"+params)
          .then(async (result) => {
              // console.log('result',result.data)
              if(result.data.success) {
                  Swal.fire({
                      title: '廠商資料刪除成功!',
                  }).then(result => {
                      history.go(-1)
                  })
              }else{
                  Swal.fire({
                      title: result.data.message,
                  }).then(result => {

                  })
              }

          })
          .catch((response) => {
              // console.log('delete error response', response);
          });
  },
  async fetchProposalCategory({ commit },  params = {}) {
    commit(SET_LOADING, true);

    ApiService.setHeader();
    return await ApiService.query("backend/categories?module=proposal_category",params)
        .then((result) => {
          result =result.data;
          commit(SET_PROPOSALS_CATE, result?.data ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
        });
  },
  async fetchProposalType({ commit },  params = {}) {
    commit(SET_LOADING, true);

    ApiService.setHeader();
    return await ApiService.query("backend/vender_types?module=proposal_type",params)
        .then((result) => {
          result =result.data;
          commit(SET_PROPOSALS_TYPE, result?.data ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          // console.log('error response', response);
        });


  },
};

import { SET_LOADING, SET_MESSAGE, SET_MESSAGES, SET_MESSAGES_TOTAL } from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchMessages({ commit }, params = {}) {
    commit(SET_LOADING, true);

    ApiService.setHeader();
    return await ApiService.query("backend/messages", params)
      .then((result) => {
        result = result.data;
        commit(SET_MESSAGES, result?.data ?? []);
        commit(SET_MESSAGES_TOTAL, result?.total ?? []);
        commit(SET_LOADING, false);

        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
  async fetchMessage({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/messages", params)
      .then((result) => {
        result = result.data;
        commit(SET_MESSAGE, result ?? {});
        commit(SET_LOADING, false);
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response.data);
        commit(SET_MESSAGE, { success: false });
      });
  },
  async createMessage({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.post("backend/messages", params)
      .then((result) => {
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
  async updateMessage({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.put("backend/messages/" + params.id, params)
      .then((result) => {})
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
  async replyMessage({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.put("backend/messages/"+params.id, params)
      .then((result) => {
        return result.data;
      })
      .catch(({ response }) => {
        // console.log("error response", response.data);
        return response.data;
      });
  },
  async deleteMessage({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.delete("backend/messages/" + params)
      .then(async (result) => {
        return result.data;
      })
      .catch((response) => {
        // console.log("delete error response", response);
      });
  },
};

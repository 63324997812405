import {
    SET_LOADING,
    SET_CS_NOTE,
    SET_SERVICE_FORMS,
    SET_SERVICE_FORM_TOTAL,
    SET_SERVICE_FORM,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
    async fetchServiceForms({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("backend/service_forms", params)
            .then((result) => {
                result = result.data;

                let temp = result?.data;
                temp = temp.map(item => {
                    item.otherForms = JSON.parse(item.otherForms);
                    return item;
                });

                commit(SET_SERVICE_FORMS, temp ?? []);
                commit(SET_SERVICE_FORM_TOTAL, result?.total ?? 0);
                commit(SET_LOADING, false);
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async fetchServiceForm({commit}, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.get("backend/service_forms/"+params, )
            .then((result) => {
                result = result.data;
                let temp = result;
                temp.otherForms = JSON.parse(temp.otherForms);
                commit(SET_SERVICE_FORM, temp ?? {});
                commit(SET_LOADING, false);
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response.data);
              commit(SET_SERVICE_FORM, response.data);
            });
    },
    async createServiceForm({commit}, params) {
        commit(SET_LOADING, true, {root: true});
        ApiService.setHeader();
        return await ApiService.post("backend/service_forms", params)
            .then((result) => {
                result = result.data;
                commit(SET_SERVICE_FORM, result?.data ?? []);
                commit(SET_LOADING, false);
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
  async addPayLinkServiceForm({commit}, params) {
    commit(SET_LOADING, true, {root: true});
    ApiService.setHeader();
    return await ApiService.post("backend/service_forms/"+params.service_form_id+'/pay_link', params)
      .then((result) => {
        result = result.data;
        return result;
      })
      .catch(({response}) => {
        // console.log('error response', response);
      });
  },
    async updateServiceForm({commit}, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/service_forms/"+params.id, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response);
            });
    },
    async deleteServiceForm({commit}, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.delete("backend/service_forms/"+params)
            .then(async (result) => {
                return result.data;
            })
            .catch((response) => {
                // console.log('delete error response', response);
            });
    },
  async addCsNote({commit}, params) {
    commit(SET_LOADING, true, {root: true});
    ApiService.setHeader();
    return await ApiService.post("backend/service_forms/"+params.id+"/addCs", params)
      .then((result) => {
        result = result.data;
        commit(SET_CS_NOTE, result?.data ?? []);
        commit(SET_LOADING, false);

        return result;
      })
      .catch(({response}) => {
        // console.log('error response', response);
      });
  },
};

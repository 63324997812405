import {
  SET_LOADING,
  SET_SETTING,
  SET_SETTINGS,
  SET_SETTINGS_TOTAL,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchSettings({ commit }, params = {}) {
    commit(SET_LOADING, true);

    ApiService.setHeader();
    return await ApiService.query("backend/settings", params)
      .then((result) => {
        result = result.data;

        commit(SET_SETTINGS, result.data ?? []);
        commit(SET_SETTINGS_TOTAL, result?.total ?? []);
        commit(SET_PAGE, result?.page ?? 1);
        commit(SET_LOADING, false);
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response);
      });
  },
  async fetchSetting({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/settings", params)
      .then((result) => {
        result = result.data;
        commit(SET_SETTING, result ?? {});
        commit(SET_LOADING, false);
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response.data);
        commit(SET_SETTING, { success: false });
      });
  },
  async createSetting({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.post("backend/settings", params)
      .then((result) => {
        result = result.data;
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response.data);
        return response.data;
      });
  },
  async updateSetting({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.put("backend/settings/" + params.id, params)
      .then((result) => {
        result = result.data;
        // console.log("updatePromo", result);
        return result;
      })
      .catch(({ response }) => {
        // console.log("error response", response.data);
        return response.data;
      });
  },

  async deleteSetting({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.delete("backend/settings/" + params)
      .then(async (result) => {
        // console.log("deletePromo", result);
      })
      .catch((response) => {
        // console.log("delete error response", response);
      });
  },
};
